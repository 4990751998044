import Layout from '../components/layout';
import Seo from '../components/seo';
import React from 'react';
import { Text } from '../components/text';
import { StaticImage } from 'gatsby-plugin-image';

const items = [
  {
    title: 'Nom',
    value: 'Ndiémé Sow',
  },
  {
    title: 'Titre',
    value: 'Enseignante-chercheure',
  },
  {
    title: 'Organisme',
    value: 'Université Assane SECK - Ziguinchor / Sénégal',
  },
  {
    title: 'Email',
    value: (
      <a className="underline" href="mailto:ndieme.sow@univ-zig.sn">
        ndieme.sow@univ-zig.sn
      </a>
    ),
  },
];

const Line = ({ title, value, className }) => (
  <div className={`${className} px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
    <Text as="dt" size="md" color="gray-500">
      {title}
    </Text>
    <Text as="dd" size="md" className="mt-1 sm:mt-0 sm:col-span-2">
      {value}
    </Text>
  </div>
);

const DescriptionList = () => (
  <dl>
    {items.map((item, index) => (
      <Line
        key={index}
        title={item.title}
        value={item.value}
        className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}
      />
    ))}
  </dl>
);

const AboutPage = () => (
  <Layout>
    <Seo
      title="À propos"
      description="Ndiémé Sow est une enseignante-chercheure à l’Université Assane SECK de Ziguinchor"
    />

    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 lg:pt-24">
      <div className="text-center">
        <Text as="h1" size="4xl" weight="extrabold" color="primary-500">
          À Propos
        </Text>
        <Text as="p" size="lg" color="gray-500" className="mt-4 max-w-2xl mx-auto">
          Enseignante-chercheure à l’Université Assane SECK de Ziguinchor
        </Text>
      </div>
    </div>
    <div className="relative mt-8 sm:mt-12 pb-20 lg:pb-28">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white max-w-3xl mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none">
          <div className="px-6 py-8 lg:p-12 lg:text-xl lg:flex lg:items-center">
            <div className="flex-shrink-0 text-center">
              <StaticImage
                className="h-32 w-32 rounded-full text-gray-300 shadow-solid inline-block"
                src="../images/ndiemesow.png"
                alt="Amadou Sall"
              />
            </div>
            <div className="mt-4 lg:mt-0 lg:ml-8">
              Ndiémé Sow est sociolinguiste, maîtresse de conférences à l’Université Assane SECK.
              Elle est chercheure au centre de recherche interdisciplinaire sur les langues, les
              littératures, les arts et les cultures. Elle s’intéresse particulièrement à la
              dynamique interactionnelle chez les jeunes et aux rapports entre mobilités, espaces
              sociolinguistiques, éducation et pratiques plurilingues. A ce jour, elle travaille sur
              la diversité linguistique dans des espaces frontaliers. Elle dirige un projet sur les
              relations entre les dynamiques migratoires et la mixité socio-langagière.
            </div>
          </div>
          <DescriptionList />
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
